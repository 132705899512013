import React from 'react';
import Form from '@strata/tempo/lib/form';
import Input from '@strata/tempo/lib/input';
import Button from '@strata/tempo/lib/button';
import { EmailFormService } from './data/EmailFormService';
import InactiveLogoutMessage from './InactiveLogoutMessage';
import { IEmail } from './data/IEmail';

export interface IEmailFormProps {
  email: string;
  onEmailFormNext: (emailDto: IEmail, user: string) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  showInactiveLogoutMessage: boolean;
}

interface IEmailFormValues {
  [name: string]: string;
}

const EmailForm: React.FC<IEmailFormProps> = ({ email, onEmailFormNext, setLoading, showInactiveLogoutMessage }) => {
  const [form] = Form.useForm<IEmailFormValues>();

  const onFormFinish = async (values: IEmailFormValues) => {
    setLoading(true);
    try {
      const response = await EmailFormService.postEmail(values.Username);
      if (response.ssoRedirectUrl) {
        setLoading(false);
        window.location.href = response.ssoRedirectUrl;
      } else {
        onEmailFormNext(response, values.Username);
      }
    } catch (error) {
      onEmailFormNext(error as IEmail, values.Username);
    }
  };

  return (
    <>
      {showInactiveLogoutMessage && <InactiveLogoutMessage />}
      <Form layout='vertical' form={form} onFinish={(values) => onFormFinish(values)}>
        <Form.Item label='Email or Username' name='Username' initialValue={email} rules={[{ required: true, message: 'Enter an Email or Username' }]}>
          <Input autoFocus />
        </Form.Item>
        <Form.Item name='next'>
          <Button block type='primary' htmlType='submit' disabled={email === ''}>
            Next
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default EmailForm;
