import { getHttpService } from '@strata/core/lib';
import { IDatabase } from './IDatabase';

const { httpPost } = getHttpService(window.location.origin, () =>
  Promise.resolve({
    credentials: 'same-origin',
    headers: new Headers({
      'Content-Type': 'application/json; charset=utf-8'
    })
  })
);

export const DatabaseFormService = {
  postDatabaseSelection: (dbGuid: string): Promise<IDatabase> => {
    return httpPost<IDatabase>(`/api/login/selectDatabase`, dbGuid);
  }
};
