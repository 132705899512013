import React from 'react';
import Input from '@strata/tempo/lib/input';
import Form from '@strata/tempo/lib/form';
import Button from '@strata/tempo/lib/button';
import { ILogin } from './data/ILogin';
import { LoginFormService } from './data/LoginFormService';

export interface ILoginFormProps {
  email: string;
  onLoginFormNext: (emailDto: ILogin, errors?: string[]) => void;
  orgPin: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ILoginFormValues {
  [name: string]: string;
}

const LoginForm: React.FC<ILoginFormProps> = ({ email, orgPin, onLoginFormNext, setLoading }) => {
  const [form] = Form.useForm<ILoginFormValues>();

  const onFormFinish = async (values: ILoginFormValues) => {
    setLoading(true);
    try {
      const response = await LoginFormService.postUsername(values.OrgPin, values.Username, values.Password);
      if (response.returnUrl) {
        setLoading(false);
        window.location.href = response.returnUrl;
      }
      onLoginFormNext(response);
    } catch (error) {
      onLoginFormNext(error as ILogin);
    }
  };

  return (
    <Form layout='vertical' form={form} onFinish={onFormFinish}>
      <Form.Item label='Username' name='Username' initialValue={email} rules={[{ required: true, message: 'Enter an Email or Username' }]}>
        <Input />
      </Form.Item>
      <Form.Item label='Password' name='Password' rules={[{ required: true, message: 'Enter your Password' }]}>
        <Input type='password' autoFocus />
      </Form.Item>
      <Form.Item label='Org PIN' name='OrgPin' initialValue={orgPin} rules={[{ required: true, message: 'Enter an Org PIN' }]}>
        <Input type='tel' />
      </Form.Item>
      <Form.Item name='login'>
        <Button block type='primary' htmlType='submit' disabled={email === '' || (orgPin === '' && !form.isFieldsTouched())}>
          Next
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
