import React from 'react';
import Text from '@strata/tempo/lib/text';
import Link from '@strata/tempo/lib/link';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className='idp-footer'>
      <Link href='https://www.stratadecision.com/privacypolicy/'>
        <Text size='small' color='white'>
          Privacy Policy
        </Text>
      </Link>
      <br />
      <Text size='small' color='white'>
        {`© 2006-${currentYear} Strata Decision Technology, LLC. All rights reserved.`}
      </Text>
    </div>
  );
};

export default Footer;
