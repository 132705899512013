import React from 'react';
import { useLocation } from 'react-router';

const Logout: React.FC = () => {
  const location = useLocation();
  const decodedURL = decodeURIComponent(decodeURIComponent(location.search));
  const urlParams = new URLSearchParams(decodedURL);
  const redirectUrl = urlParams.get('redirect_url') ?? '';
  const iframeUrl = urlParams.get('iframe_url') ?? '';
  const redirect = (shouldRedirect: boolean) => {
    if (shouldRedirect) {
      window.location.href = redirectUrl;
    }
  };
  return (
    <div style={{ display: 'none' }}>
      <iframe title='front_channel_logout_iframe' width='0' height='0' src={iframeUrl} />
      {setTimeout(() => {
        redirect(true);
      }, 250)}
      ;
    </div>
  );
};

export default Logout;
