import { getHttpService } from '@strata/core/lib';
import { ILogin } from '../../login/data/ILogin';

const { httpGet } = getHttpService(window.location.origin, () =>
  Promise.resolve({
    credentials: 'same-origin'
  })
);

export const SsoLoginService = {
  postSsoLogin: (scheme: string | null, clientId: string | null): Promise<ILogin> => {
    let url = '/api/externalCallback';
    if (scheme) {
      url += `/${scheme}`;
    }
    if (clientId) {
      url += `?clientId=${clientId}`;
    }
    return httpGet<ILogin>(`${url}`, {});
  }
};
