import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import ErrorBoundary from '@strata/tempo/lib/errorboundary';
import Unload from '@strata/tempo/lib/unload';
import Login from './shared/Basepage';
import Logout from './logout/Logout';
import WalkMe from './shared/WalkMe';

const App = () => {
  return (
    <ErrorBoundary disableGlobalJavascriptErrorHandling>
      <Unload>
        <WalkMe />
        <CookiesProvider>
          <BrowserRouter>
            <Route key='/Login' path='/Login' exact component={Login} />
            <Route key='/PostLogout' path='/PostLogout' exact component={Logout} />
          </BrowserRouter>
        </CookiesProvider>
      </Unload>
    </ErrorBoundary>
  );
};

export default App;
