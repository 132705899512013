const WalkMe = () => {
  const script = document.createElement('script');
  script.src = window.config.walkMeUrl;
  script.async = true;
  script.type = 'text/javascript';
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(script, s);
  window._walkmeConfig = { smartLoad: true };
  return null;
};

export default WalkMe;
