import { getHttpService } from '@strata/core/lib';
import { IAnnouncement } from './IAnnouncement';

const { httpGet } = getHttpService(window.location.origin, () =>
  Promise.resolve({
    credentials: 'same-origin'
  })
);

const url = '/api/announcement';

export const AnnouncementService = {
  getMessage: (): Promise<IAnnouncement> => {
    return httpGet<IAnnouncement>(`${url}/message`, {});
  }
};
