import React from 'react';

const InactiveLogoutMessage: React.FC = () => {
  return (
    <div className='idp-inactive-logout-message-box'>
      <label>Your session timed out due to inactivity.</label>
    </div>
  );
};

export default InactiveLogoutMessage;
