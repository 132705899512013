import { getHttpService } from '@strata/core/lib';
import { ILogin } from './ILogin';

const { httpPost } = getHttpService(window.location.origin, () =>
  Promise.resolve({
    credentials: 'same-origin'
  })
);

export const LoginFormService = {
  postUsername: (orgPin: string, username: string, password: string): Promise<ILogin> => {
    return httpPost<ILogin>(`/api/login${window.location.search}`, orgPin, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Basic ${window.btoa(`${username}:${password}`)}`
      })
    });
  }
};
