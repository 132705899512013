import React, { useEffect, useState } from 'react';
import Form from '@strata/tempo/lib/form';
import RadioGroup from '@strata/tempo/lib/radiogroup';
import Button from '@strata/tempo/lib/button';
import { DatabaseFormService } from './data/DatabaseFormService';
import { IDatabase } from './data/IDatabase';
import { IAvailableDatabases } from '../shared/data/IAvailableDatabases';

export interface IDatabaseFormProps {
  lastSelectedDb: string;
  availableDbs: IAvailableDatabases[];
  onDatabaseFormNext: (dbDto: IDatabase) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IDatabaseFormValues {
  [name: string]: string;
}

const DatabaseForm: React.FC<IDatabaseFormProps> = ({ lastSelectedDb, availableDbs, onDatabaseFormNext, setLoading }) => {
  const [form] = Form.useForm<IDatabaseFormValues>();
  const [databaseGuid, setDatabaseGuid] = useState<string>('00000000-0000-0000-0000-000000000000');
  const databaseArray = Array.isArray(availableDbs) ? availableDbs : [];
  const dbText =
    databaseArray.length === 1
      ? ["Your database is unavailable because it's receiving updates.", 'Try logging in again in a few minutes.']
      : ["Your databases are unavailable because they're receiving updates.", 'Try logging in again in a few minutes.'];
  const [, forceUpdate] = useState<Record<never, never>>(); // To disable submit button at the beginning.

  useEffect(() => {
    const dbs = databaseArray;
    const maintModeDbs = dbs.filter((db) => db.isInMaintenanceMode).map((db) => db.databaseGuid);
    if (maintModeDbs.length === dbs.length) {
      const errorDto: IDatabase = {
        returnUrl: '',
        loading: false,
        errorMessages: dbText
      };
      onDatabaseFormNext(errorDto);
    }
    forceUpdate({});
  }, []);

  const setLastSelectedDB = (lastSelectedDatabaseGuid: string, databaseGuid: string) => {
    const dbArray = databaseArray;
    const maintModeDbArray = dbArray.filter((db) => !db.isInMaintenanceMode);
    const infinityDbArray = maintModeDbArray.filter((d) => parseInt(d.appVersion) >= 2020).map((db) => db.databaseGuid);
    if (lastSelectedDatabaseGuid !== '00000000-0000-0000-0000-000000000000' && databaseGuid === '00000000-0000-0000-0000-000000000000' && infinityDbArray.indexOf(lastSelectedDatabaseGuid) >= 0) {
      setDatabaseGuid(lastSelectedDatabaseGuid);
    }
    return lastSelectedDatabaseGuid;
  };

  const databaseRadioButtonItem = (database: IAvailableDatabases) => {
    const getDatabaseLabel = database.isInMaintenanceMode ? `${database.name} — in maintenance` : `${database.name}`;
    const isDisabled = database.isInMaintenanceMode;
    return {
      value: database.databaseGuid,
      label: getDatabaseLabel,
      disabled: isDisabled
    };
  };

  const onFormFinish = async (values: IDatabaseFormValues) => {
    setLoading(true);
    try {
      const response = await DatabaseFormService.postDatabaseSelection(values.Database);
      onDatabaseFormNext(response);
    } catch (error) {
      onDatabaseFormNext(error as IDatabase);
    }
  };

  return (
    <Form layout='vertical' onFinish={onFormFinish} form={form}>
      <Form.Item label='Database' name='Database' initialValue={setLastSelectedDB(lastSelectedDb, databaseGuid)} rules={[{ required: true, message: 'Select a Database' }]}>
        <RadioGroup options={databaseArray.map(databaseRadioButtonItem)} />
      </Form.Item>
      <Form.Item shouldUpdate={true}>
        {() => (
          <Button block type='primary' htmlType='submit' disabled={!form.isFieldsTouched(true) && databaseGuid === '00000000-0000-0000-0000-000000000000'}>
            Log In
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
export default DatabaseForm;
