import { getHttpService } from '@strata/core/lib';
import { IEmail } from './IEmail';

const { httpPost } = getHttpService(window.location.origin, () =>
  Promise.resolve({
    credentials: 'same-origin'
  })
);

export const EmailFormService = {
  postEmail: (username: string): Promise<IEmail> =>
    httpPost<IEmail>(`/api/initiateLogin${window.location.search}`, username, {
      headers: new Headers({
        'Content-Type': 'application/json; charset=utf-8'
      })
    })
};
