import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useCookies } from 'react-cookie';
import { isIE } from 'react-device-detect';

import Banner from '@strata/tempo/lib/banner';
import Button from '@strata/tempo/lib/button';
import Card from '@strata/tempo/lib/card';

import { type IEmail } from '../email/data/IEmail';
import { type ILogin } from '../login/data/ILogin';
import { type IDatabase } from '../database/data/IDatabase';
import { type IAvailableDatabases } from './data/IAvailableDatabases';

import { SsoLoginService } from './data/SsoLoginService';
import { AnnouncementService } from './data/AnnouncementService';

import EmailForm from '../email/EmailForm';
import LoginForm from '../login/LoginForm';
import DatabaseForm from '../database/DatabaseForm';
import Footer from './Footer';
import ErrorMessage from './ErrorMessage';

const genericErrorMessage = ['An unknown error occurred.', 'Try again and contact your administrator if the issue continues.'];

const Login = () => {
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false);
  const [showDatabaseForm, setShowDatabaseForm] = useState<boolean>(false);
  const [errorResponseMessage, setErrorResponseMessage] = useState<string[]>([]);
  const [announcementMessage, setAnnouncementMessage] = useState<string>('');
  const [availableDatabases, setAvailableDatabases] = useState<IAvailableDatabases[]>([]);
  const [cookie] = useCookies(['LastUsername', 'LastOrgPin', 'LastSelectedDatabaseGuid']);
  const [loading, setLoading] = useState<boolean>(false);
  const [formUsername, setFormUsername] = useState<string>('');
  const location = useLocation();
  const decodedURL = decodeURIComponent(decodeURIComponent(location.search));
  const urlParams = new URLSearchParams(decodedURL);
  const isSsoCompleted = urlParams.get('ssoCompleted');
  const scheme = urlParams.get('scheme');
  const clientId = urlParams.get('client_id');
  const username = urlParams.get('login_hint') || cookie.LastUsername;
  const orgPin = urlParams.get('orgPin') || (cookie.LastOrgPin && String(cookie.LastOrgPin));
  const showInactiveLogoutMessage = urlParams.get('inactive_user_notification') === '1';
  const lastSelectedDb = cookie.LastSelectedDatabaseGuid || '00000000-0000-0000-0000-000000000000';
  const isErrorPage = location.search.includes('?error=true');
  const errorMessage = isErrorPage ? genericErrorMessage : errorResponseMessage;

  useEffect(() => {
    const loadAnnouncementMessage = async () => {
      const announcement = await AnnouncementService.getMessage();
      setAnnouncementMessage(announcement.message);
    };
    loadAnnouncementMessage();
  }, []);

  useEffect(() => {
    if (isSsoCompleted !== 'true') {
      return;
    }
    const loginWithSso = async () => {
      try {
        setLoading(true);
        const result = await SsoLoginService.postSsoLogin(scheme, clientId);
        if (result && result.returnUrl) {
          window.location.href = result.returnUrl;
        }
        if ((result && result.loginResultType.toString() === 'DbSelectionRequired') || result.loginResultType.toString() === 'DbInMaintenanceMode') {
          setAvailableDatabases(result.availableDatabases);
          setShowDatabaseForm(true);
          setLoading(false);
        }
      } catch (error) {
        if ((error as Response)?.status === 401) {
          setErrorResponseMessage(['Unable to log in.', 'Contact your StrataJazz system administrator.']);
          setLoading(false);
        } else {
          setErrorResponseMessage(genericErrorMessage);
          setLoading(false);
        }
      }
    };
    loginWithSso();
  }, [isSsoCompleted, scheme, clientId]);

  const onEmailFormNext = (emailDto: IEmail, formUser: string) => {
    if (emailDto.status === 401) {
      setErrorResponseMessage(['You entered an invalid Email.', 'Check your spelling or enter a Username.']);
      setLoading(false);
    }

    if (emailDto.status && emailDto.status !== 401 && !emailDto.ok && !emailDto.showPassword) {
      setErrorResponseMessage(genericErrorMessage);
      setLoading(false);
    }

    if (emailDto.showPassword) {
      setErrorResponseMessage([]);
      setFormUsername(formUser);
      setShowLoginForm(true);
      setLoading(false);
    }
  };

  const onLoginFormNext = (loginDto: ILogin) => {
    if (loginDto.status === 401) {
      setErrorResponseMessage(['Your Username, Password, or Org PIN is invalid.', 'Check your entries and try again.']);
      setLoading(false);
    } else if (loginDto.status && loginDto.status !== 401 && !loginDto.ok) {
      setErrorResponseMessage(genericErrorMessage);
      setLoading(false);
    } else if (loginDto.loginResultType.toString() === 'DbSelectionRequired' || loginDto.loginResultType.toString() === 'DbInMaintenanceMode') {
      setErrorResponseMessage([]);
      setAvailableDatabases(loginDto.availableDatabases);
      setShowDatabaseForm(true);
      setLoading(false);
    }
  };

  const onDatabaseFormNext = (databaseDto: IDatabase) => {
    if (databaseDto.errorMessages) {
      setLoading(false);
      setErrorResponseMessage(databaseDto.errorMessages);
    } else {
      setErrorResponseMessage([]);
      setLoading(false);
      window.location.href = databaseDto.returnUrl;
    }
  };

  return (
    <>
      <div className='idp-outerdiv'>
        {isIE ? (
          <div>
            <Banner
              extra={
                <Button
                  onClick={() =>
                    window.open('https://secure.stratanetwork.com/helpcenter/resources_strata/technical/technical_requirements_overview.htm?Highlight=desktop#DesktopRequirements', '_blank')
                  }
                >
                  See Requirements
                </Button>
              }
            >
              The StrataJazz platform no longer supports the Internet Explorer browser. See a list of supported browsers on the Technical Requirements Overview in Help Center.
            </Banner>
          </div>
        ) : null}
        {announcementMessage !== null && announcementMessage !== '' ? (
          <div>
            <Banner>{announcementMessage}</Banner>
          </div>
        ) : null}
        <div className='idp-innerdiv'>
          <div className='idp-logolink-container'>
            <a href='https://stratadecision.com' target='_blank' rel='noopener noreferrer'>
              <img className='idp-logolink-image' src='StrataLogo.png' title='Strata Decision Technology' alt='Strata Logo' />
            </a>
          </div>
          <Card
            removeBodyPadding
            loading={loading}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            id='cardyBoi'
          >
            {!showLoginForm && !showDatabaseForm && <EmailForm email={username} onEmailFormNext={onEmailFormNext} setLoading={setLoading} showInactiveLogoutMessage={showInactiveLogoutMessage} />}
            {showLoginForm && !showDatabaseForm && <LoginForm email={formUsername} orgPin={orgPin} onLoginFormNext={onLoginFormNext} setLoading={setLoading} />}
            {showDatabaseForm && <DatabaseForm lastSelectedDb={lastSelectedDb} availableDbs={availableDatabases} onDatabaseFormNext={onDatabaseFormNext} setLoading={setLoading} />}
            <ErrorMessage errorMessage={errorMessage} />
          </Card>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Login;
