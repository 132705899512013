import React from 'react';
import Text from '@strata/tempo/lib/text';

const ErrorMessage: React.FC<{ errorMessage: string[] }> = ({ errorMessage }) => {
  const hasErrors = errorMessage && Array.isArray(errorMessage) && errorMessage.length > 0;
  const errorBox = `${hasErrors ? 'idp-error-message-box' : ''}`;
  return (
    <div className={errorBox}>
      {hasErrors &&
        errorMessage.map((err) => (
          <Text color='error' key={err}>
            {err}
          </Text>
        ))}
    </div>
  );
};
export default ErrorMessage;
